<template>
  <div class="me-page-mobile">
    <div class=" text-center profile-details bg-loop white--text pa-md-2"
    :class="{'stopped' : createMeeting === true}"
    >


      <div class="profile-photo pt-10 pt-md-0">
        <template v-if="!$isEmpty(loadedUser.data.photoURL)">
          <img :src="loadedUser.data.photoURL.cropped" alt="" class="profile-img mb-2 rounded elevation-6">
        </template>
        <template v-else>
          <!-- <img :src="loadedUser.data.basicAvatar" alt="" class="profile-img mb-2 rounded elevation-6"> -->
          <v-avatar size="60" color="white" class="headline black--text">
            {{loadedUser.data.firstName.charAt(0)}}
          </v-avatar>
        </template>
      </div>

      <!-- <h2>{{loadedUser.data.firstName}} {{loadedUser.data.lastName}}</h2> -->
      <div v-resize-text="{ratio:1}" class="resize-text mb-4">{{loadedUser.data.firstName}} {{loadedUser.data.lastName}}</div>

      <div class="company-links mb-xs-4 mb-md-0 d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn class="d-flex" v-for="(link, i) in loadedUser.data.links" :href="link.url" :key="`link-${i}`">
          <v-icon>{{siteIcon(link.url)}}</v-icon>
        </v-btn>
        <!-- <div><a href="#" class="white--text">@Keybot</a></div>
        <div><a href="#" class="white--text">@HighGravity</a></div> -->
      </div>
      <div  class="pb-6 mt-2">
        <v-btn color="primary"
        class="mx-xs-auto meet-me"
        depressed x-large
        @click="createProposal"
        v-if="!myProfile"
        :disabled="myProfile"
        >
          Meet with Me <span class="px-2" style="opacity:.5">|</span><strong>${{loadedUser.data.rate}}</strong>
        </v-btn>


      </div>
    </div>

    <v-tabs v-model="tab" grow color="primary">
      <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
      <v-tab key="about">About Me</v-tab>
      <v-tab key="skills">Skills</v-tab>
      <v-tab key="social">Social</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item key="about">
        <div class="text-center px-4 py-6">
          <div class="text-h5 mb-4">Hey, I'm {{loadedUser.data.firstName}}</div>
          <p class="text-center text-md-left" style="white-space: pre-line;">{{loadedUser.data.description}}</p>
        </div>
      </v-tab-item>


      <v-tab-item key="skills" class="pt-6 pb-6 grey lighten-3">
        <!-- <v-chip
          v-for="skill in loadedUser.data.skills" :key="skill.name"
          class="d-block ma-2 pl-0 shadow"
          label
          large
          color="white"
          text-color="black"
          >
          <v-avatar tile left class="pl-8 pr-4 grey--text">{{skill.meetings || 0}}</v-avatar>
          {{skill.name}}
          <template v-if="skill.rating !== null">
            <v-icon right v-if="skill.rating > 3" color="green">mdi-menu-up</v-icon>
            <v-icon right v-if="skill.rating < 3" color="red">mdi-menu-down</v-icon>
          </template>
          <template v-else>
            <small v-if="skill.rating === null" class="text--secondary">No rating</small>
          </template>
        </v-chip> -->
        <div class="text-h5 mb-4 text-center ">Let's meet about:</div>
        <div
          v-for="skill in loadedUser.data.skills" :key="skill.name"
          class="d-flex align-center justify-space-between ma-2 px-4 py-4 shadow rounded white"
          >
          <span class="pr-4 grey--text">{{skill.meetings || 0}}</span>
          <span class="flex-grow-1">{{skill.name}}</span>
          <template v-if="skill.rating !== null">
            <v-icon right v-if="skill.rating > 3" color="green">mdi-menu-up</v-icon>
            <v-icon right v-if="skill.rating < 3" color="red">mdi-menu-down</v-icon>
          </template>
          <template v-else>
            <small v-if="skill.rating === null" class="text--secondary">No rating</small>
          </template>
        </div>

      </v-tab-item>



          <v-tab-item key="social">
            <div class=" px-4 py-6">
              <div class="text-h5 mb-4">Follow me here:</div>
              <template v-if="$isEmpty(loadedUser.data.links)">
                <v-alert
                  type="info"
                  color="grey"
                  text
                  icon="mdi-heart-broken"
                >
                <h3 class="error--text">Ouch...</h3>
                  no social networks were added yet.
                </v-alert>
              </template>
            </div>
          </v-tab-item>

        </v-tabs-items>




</div>
</template>

<script>
import { storage } from '@/firebase';
export default {
  name: 'userMobile',
  props:['loadedUser', 'myProfile', 'createMeeting'],
  computed: {
    // isAuthenticated: function (){ return this.$store.state.userId},
    // user: function (){ return this.$store.state.user},
  },

  data: () => ({
    photoURL: null,
    tab: 'skills',
  }),
  methods:{
    createProposal(){
      this.$emit('create-proposal', true)
    }
  },
  mounted(){

  }
};
</script>


<style lang="scss">
.me-page-mobile{
  .v-tab{
    font-weight: bold;
  }
}
</style>
